<template>
  <div id="user-profile">
    <user-header />
    <section id="profile-info">
      <b-row>
        <b-col
          lg="8"
          cols="12"
        >
          <organization-info />
        </b-col>
      </b-row>
    </section>
  </div>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'
import UserHeader from './UserHeader.vue'
import OrganizationInfo from './OrganizationInfo.vue'

export default {
  components: {
    UserHeader,
    BRow,
    BCol,
    OrganizationInfo,
  },
}
</script>

<style lang="scss" >
@import '@core/scss/vue/pages/page-profile.scss';
</style>
